import React from 'react';
import MainDesk from './components/MainDesk/MainDesk';
import './App.scss';

function App() {
  return (
    <main className='main'>
      <MainDesk/>
    </main>
  );
}

export default App;
